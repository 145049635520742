import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type {
  BuildMetaReleaseInfo,
  ProductReleasePlanCloudSaving,
  ProductReleasePlanCommunity,
  ReleasePlanInfo,
  ReleasePlanInformation,
  ReleasePlanRelationSelling,
  ReleasePlanStatus
} from '@/types/release-plan/release-plan.type';
import type {
  InGameStoreListRequest,
  ManualLiveApplicationRequest,
  ProductReleaseHistoryListRequest,
  ReleasePlanVerifyRequest,
  ReviewRejectionInformationRequest,
  SaveReleaseInformationRequest
} from '@/types/release-plan/release-plan-request.type';
import type {
  InGameStoreListResponse,
  ProductReleaseHistoryListResponse,
  ProductReleaseHistoryListSimpleResponse,
  ReviewRejectionInformationResponse
} from '@/types/release-plan/release-plan-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';
import { toQueryString } from '@/utils/common.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=461257313
export const fetchReleasePlanInformationApi = async (
  productNo: number
): Promise<ReleasePlanInformation | undefined> => {
  const url = `${apiUrl}/products/release/${productNo}`;
  const { data } = await useRequest<ReleasePlanInformation>(url, {
    method: ApiMethod.Get,
    showCommonError: false
  });
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=461501786
export const saveReleaseInformationApi = async (
  productNo: number,
  params: SaveReleaseInformationRequest
): Promise<number | undefined> => {
  const url = `${apiUrl}/products/release/${productNo}`;
  const { data } = await useRequest<{ releaseNo: number }>(url, {
    method: ApiMethod.Post,
    data: camelToSnake(params),
    showCommonError: false
  });

  return data?.releaseNo;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=461502540
export const fetchProductReleaseStatusApi = async (
  productNo: number,
  showLoading: boolean = true
): Promise<ReleasePlanStatus | undefined> => {
  const url = `${apiUrl}/products/release/${productNo}/status`;
  const { data } = await useRequest<ReleasePlanStatus>(url, { showLoading, showCommonError: false });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=461502368
export const fetchProductReleaseHistoryListSimpleApi = async (
  params: ProductReleaseHistoryListRequest
): Promise<ProductReleaseHistoryListSimpleResponse | undefined> => {
  const url = `${apiUrl}/products/history/simple/search?${toQueryString(camelToSnake(params))}`;
  const { data } = await useRequest<ProductReleaseHistoryListSimpleResponse>(url, {
    showLoading: true
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=487009538
export const fetchProductReleaseHistoryListApi = async (
  params: ProductReleaseHistoryListRequest
): Promise<ProductReleaseHistoryListResponse | undefined> => {
  const url = `${apiUrl}/products/history/search?${toQueryString(camelToSnake(params))}`;
  const { data } = await useRequest<ProductReleaseHistoryListResponse>(url, {
    showLoading: true
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=462608066
export const fetchProductReleasePlanApi = async (
  productNo: number,
  showLoading: boolean = true
): Promise<ReleasePlanInfo | undefined> => {
  const { data } = await useRequest<ReleasePlanInfo>(
    `${apiUrl}/products/release/${productNo}/plan`,
    {
      method: ApiMethod.Get,
      showCommonError: false,
      showLoading,
      headers: {
        ...generateHeader('X-Nation', 'X-Lang')
      }
    }
  );
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=461502230
export const updateProductReleaseManualLiveApi = async (
  productNo: number,
  params: ManualLiveApplicationRequest
): Promise<{ success: boolean; image: string } | undefined> => {
  const { data } = await useRequest<{ success: boolean; image: string }>(
    `${apiUrl}/products/release/${productNo}`,
    {
      method: ApiMethod.Put,
      data: camelToSnake(params)
    }
  );
  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+cloud+saving+%7C+get+by+gameId
export const fetchCloudSavingByGameIdApi = async (
  gameId: string
): Promise<ProductReleasePlanCloudSaving | undefined> => {
  const { data } = await useRequest<ProductReleasePlanCloudSaving>(
    `${apiUrl}/cloud-saving/${gameId}`,
    {
      method: ApiMethod.Get
    }
  );
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=461501516
export const fetchCommunityInformationApi = async (
  productNo: number
): Promise<ProductReleasePlanCommunity | undefined> => {
  const { data } = await useRequest<ProductReleasePlanCommunity>(
    `${apiUrl}/products/${productNo}/community`,
    {
      method: ApiMethod.Get,
      showCommonError: false
    }
  );
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924186
export const fetchLatestReviewRejectionInformationApi = async (
  params: ReviewRejectionInformationRequest
): Promise<ReviewRejectionInformationResponse | undefined> => {
  const { data } = await useRequest<ReviewRejectionInformationResponse>(
    `${apiUrl}/attempt-verify/reject`,
    {
      method: ApiMethod.Get,
      params: camelToSnake(params),
      showCommonError: false
    }
  );
  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+In-game+purchase+-+In-game+shop+%7C+GET+search+shops
export const fetchInGameStoreListApi = async (
  params: InGameStoreListRequest
): Promise<InGameStoreListResponse[] | undefined> => {
  params.gameCodeList = params.gameCodeList.toString();
  const { data } = await useRequest<{ contents: InGameStoreListResponse[] }>(
    `${apiUrl}/in-game/shops`,
    {
      method: ApiMethod.Get,
      params: camelToSnake(params)
    }
  );
  return data?.contents;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924181
export const releasePlanVerifyApi = async (
  params: ReleasePlanVerifyRequest
): Promise<boolean | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/attempt-verify`, {
    method: ApiMethod.Post,
    data: camelToSnake(params),
    showCommonError: true
  });
  return data?.success;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=458924183
export const releasePlanVerifyCancelApi = async (
  params: ReleasePlanVerifyRequest
): Promise<boolean | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/attempt-verify/cancel`, {
    method: ApiMethod.Post,
    data: camelToSnake(params),
    showCommonError: true
  });
  return data?.success;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=499293150
export const fetchReleasePlanImageReleasedApi = async (
  productNo: string | number
): Promise<string | undefined> => {
  const { data } = await useRequest<{ image: string }>(
    `${apiUrl}/products/release/${productNo}/image`
  );
  return data?.image;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=511346721
export const fetchReleasePlanRelationSellingApi = async (
  productNo: string | number
): Promise<ReleasePlanRelationSelling | undefined> => {
  const { data } = await useRequest<ReleasePlanRelationSelling>(
    `${apiUrl}/products/release/${productNo}/relation/sales`
  );
  return data;
};

// https://wiki.smilegate.net/display/SGVTN/API+get+build+meta+release+info
export const getBuildMetaReleaseInfoApi = async (
  buildId: string
): Promise<BuildMetaReleaseInfo | undefined> => {
  const { data } = await useRequest<BuildMetaReleaseInfo>(`${apiUrl}/builds/meta-release/info`, {
    method: ApiMethod.Get,
    params: camelToSnake({
      buildId
    })
  });
  return data;
};
